/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import { Room, RoomGroup } from './rooms'

export const CONTENTFUL_BUNKHOSUES = graphql`
  query BunkHousesQuery {
    bunkhouses: contentfulRoomType(slug: { eq: "bunkhouses" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
  }
`

export default function BunkHouses() {
  const { bunkhouses } = useStaticQuery(CONTENTFUL_BUNKHOSUES)

  return <RoomGroup roomType={bunkhouses} hideTopSection />
}
